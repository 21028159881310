window.addEventListener("load", (event) => {
  const fileInput = document.querySelector('input[type="file"]');
  if(fileInput) {
    const changeLabelText = (event) => {
      let element = document.querySelector(`label[for="${event.target.id}"]`);
      if (element) {
        element.innerHTML = event.target.value.replace('fakepath', '<secure_path>');
      }
    }
  
    fileInput.addEventListener('change', changeLabelText);
  }
});