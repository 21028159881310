// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./config/alpine";
import "./config/multiselect";
import "./config/sor_table";
import "./utils/dark_mode";
import "./utils/sidebar";
import "flowbite/dist/flowbite";
import "./controllers";
import "./utils/attachments";
import "./utils/trip_requests/table";
import "./utils/trip_requests/form";
import "./utils/trip_requests/mass_edit";
import "./components/passengers/index/loader_button";
import "./components/passengers/index/sync_button";
import "./components/cost_centers/index/loader_button";
import "./components/cost_centers/index/sync_button";
import "./utils/passengers/sync_job";
import "./utils/cost_centers/sync_job";
import "./utils/addresses/commune_selector";
import "./utils/special_trips/comeback_address";

import "tw-elements/dist/js/tw-elements.umd.min.js";
