const bindMassEditEvent = () => {
  let editButton = document.getElementsByClassName("mass-edit-btn")[0];

  const toogleEdit = () => {
    let editContainer = document.getElementById("trip-requests-edit");
    if (editContainer.classList.contains("hidden")) {
      editContainer.classList.remove("hidden");
    } else {
      editContainer.classList.add("hidden");
    }
  };

  if (editButton) {
    editButton.addEventListener("click", () => toogleEdit());
  }
};

const bindEditFormListener = () => {
  let massEditForm = document.getElementById("tripRequestsMassEditForm");

  const sendForm = (event) => {
    // event.preventDefault();
    const formData = new FormData(event.target);
    const value = Object.fromEntries(formData.entries());
    console.log({ value });
  };

  if (massEditForm) {
    massEditForm.addEventListener("submit", (event) => sendForm(event));
  }
};

window.addEventListener("load", () => {
  bindMassEditEvent();
  bindEditFormListener();
});

document.addEventListener("turbo:before-fetch-response", function () {
  setTimeout(() => {
    bindMassEditEvent();
    bindEditFormListener();
  }, 300);
});
