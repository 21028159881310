import { toCapitalCase } from "../capitalize.js";
// This script control trip_request_address_id field hehavior depending on selected passenger on trip_request_address_id field
window.addEventListener("load", () => {
  // const addressSelect = document.getElementById("trip_request_address_id");
  const passengerInput = document.getElementById(
    "trip_request_passenger_input"
  );
  // if (addressSelect && passengerInput) {
  if (passengerInput) {
    // const addressSelectClone = document.getElementById("trip_request_address_id").cloneNode(true);

    const preloadData = () => {
      let passengerName = passengerInput.textContent;
      if (passengerName !== "" && passengerName !== "Seleccione un pasajero") {
        addressSelect.disabled = false;
        fillAddresses(passengerName);
      } else {
        addressSelect.disabled = true;
      }
    };

    const fillAddresses = (value) => {
      if (value == "") {
        empty(addressSelect);
        addressSelect.disabled = true;
      } else {
        let options = Array.from(
          addressSelectClone.querySelectorAll(
            'select#trip_request_address_id > optgroup[label="' + value + '"]'
          )
        )[0];

        if (options) {
          empty(addressSelect);
          addressSelect.disabled = false;
          addressSelect.add(options);
        } else {
          empty(addressSelect);
        }
      }
    };

    const empty = (element) => {
      while (element.firstChild) element.removeChild(element.firstChild);
    };

    window.fillPassengerContactData = () => {
      var loc = window.location.href.split("/");
      if (!loc[loc.length - 1].includes("edit")) {
        var id = document.getElementById("trip_request_passenger").value;
        if (id !== "" && id !== undefined && id !== null) {
          var xmlHttp = new XMLHttpRequest();
          xmlHttp.open(
            "GET",
            "/admin/passengers/" + id + "/contact_data",
            false
          );
          xmlHttp.send(null);
          var data = JSON.parse(xmlHttp.responseText);
          document.getElementById("trip_request_contact_phone").value =
            data.phone;
          document.getElementById("trip_request_contact_email").value =
            data.email;
        }
      }
    };

    // preloadData();
    // document.getElementById("trip_request_passenger_button").addEventListener("DOMNodeInserted", () => [fillAddresses(passengerInput.textContent), fillPassengerContactData()]);
    // document
    //   .getElementById("trip_request_passenger_button")
    //   .addEventListener("DOMNodeInserted", () => fillPassengerContactData());

    const passengerButton = document.getElementById(
      "trip_request_passenger_button"
    );

    if (passengerButton) {
      const observer = new MutationObserver(() => fillPassengerContactData());

      observer.observe(passengerButton, {
        childList: false,
        attributes: true,
        subtree: false, //Omita o ponga false si no quiere controlar los cambios en los hijos
      });
    }
  }
});
