// This script was provided by TUK https://tailwinduikit.com/components/webapp/table/advance_table/compact_table_with_actions_and_select

window.dropdownFunction = function (element) {
  var dropdowns = document.getElementsByClassName("dropdown-content");
  var i;
  let list =
    element.parentElement.parentElement.getElementsByClassName(
      "dropdown-content"
    )[0];
  list.classList.add("target");
  for (i = 0; i < dropdowns.length; i++) {
    if (!dropdowns[i].classList.contains("target")) {
      dropdowns[i].classList.add("hidden");
    }
  }
  list.classList.toggle("hidden");
};

window.onclick = function (event) {
  if (!event.target.matches(".dropbtn")) {
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      openDropdown.classList.add("hidden");
    }
  }
};

window.checkAll = function (element) {
  let rows =
    element.parentElement.parentElement.parentElement.nextElementSibling
      .children;
  for (var i = 0; i < rows.length; i++) {
    if (element.checked) {
      rows[i].classList.add("bg-gray-100");
      rows[i].classList.add("dark:bg-gray-700");
      let checkbox = rows[i].querySelector('input[type="checkbox"]');
      if (checkbox) {
        checkbox.checked = true;
      }
    } else {
      rows[i].classList.remove("bg-gray-100");
      rows[i].classList.remove("dark:bg-gray-700");
      let checkbox = rows[i].querySelector('input[type="checkbox"]');
      if (checkbox) {
        checkbox.checked = false;
      }
    }
  }
};

window.tableInteract = function (element) {
  var single = element.parentElement.parentElement;
  single.classList.toggle("bg-gray-100");
  single.classList.toggle("dark:bg-gray-700");
};

let temp = 0;
window.pageView = function (val) {
  let text = document.getElementById("page-view");
  if (val) {
    if (temp === 2) {
      temp = 0;
    } else {
      temp = temp + 1;
    }
  } else if (temp !== 0) {
    temp = temp - 1;
  }
  switch (temp) {
    case 0:
      text.innerHTML = "Viewing 1 - 20 of 60";
      break;
    case 1:
      text.innerHTML = "Viewing 21 - 40 of 60";
      break;
    case 2:
      text.innerHTML = "Viewing 41 - 60 of 60";
    default:
  }
};

// window.addEventListener("load", (event) => {
//   const paginatorPerPageOptions = document.getElementById("paginator_per_page");
//   if (paginatorPerPageOptions) {
//     const addPaginatorParamsToPage = (event) => {
//       debugger;
//       let prevSearch = window.location.search;
//       let paginatorQuery = `per_page=${paginatorPerPageOptions.value}`;
//       window.location.search +=
//         prevSearch === ""
//           ? `?${paginatorQuery}`
//           : (prevSearch += `&${paginatorQuery}`);
//     };
//     paginatorPerPageOptions.addEventListener(
//       "change",
//       addPaginatorParamsToPage
//     );
//   }
// });
