import { loadingButton } from '../../components/cost_centers/index/loader_button.js';
import { syncButton } from '../../components/cost_centers/index/sync_button.js';

window.addEventListener('load', () => {
  if (document.getElementById("cost_center-sync-btn")) {

    let currentJobId = localStorage.getItem('cost_center_job_id');

    const changeButtonInterface = (status) => {
      if (currentJobId && currentJobId != '') {
        let btnContent;
        switch (status) {
          case 'running':
            btnContent = loadingButton('Procesando...');
            break;
            case 'failed':
            btnContent = syncButton('Falló la sincronización', 'text-red-700');
            currentJobId = '';
            localStorage.setItem('cost_center_job_id', currentJobId);
            break;
          case 'success':
            btnContent = syncButton('Sincronizado', 'text-green-700');
            currentJobId = '';
            localStorage.setItem('cost_center_job_id', currentJobId);
            setTimeout(() => window.location.reload(), 5000);
            break;
          default:
            break;
        }
        var btnContainer = document.getElementById('cost_center-sync-btn-holder');
        if(btnContainer) {
          btnContainer.innerHTML = btnContent ;
        }
      }
    };

    const readJobStatus = () => {
      if (currentJobId && currentJobId != '') {
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.open( "GET", `/admin/cost_centers/check_job_status?job_id=${currentJobId}`, false );
        xmlHttp.send(null);
        var data = JSON.parse(xmlHttp.responseText);
        changeButtonInterface(data.job_status)
      }
    };

    const syncPassengerData = () => {
      var xmlHttp = new XMLHttpRequest();
      xmlHttp.open( "GET", "/admin/cost_centers/sync_data", false );
      xmlHttp.send(null);
      var data = JSON.parse(xmlHttp.responseText);
      currentJobId = data.job_id;
      localStorage.setItem('cost_center_job_id', currentJobId);
      document.getElementById('cost_center-sync-btn-holder').innerHTML = loadingButton('Procesando...');
    }

    document.getElementById("cost_center-sync-btn").addEventListener("click", () => syncPassengerData())
    setInterval(() => {
      readJobStatus()
    }, 10000);
    
  }
});
  