// Whenever the user explicitly chooses light mode
// localStorage.theme = 'light'

// Whenever the user explicitly chooses dark mode
// localStorage.theme = 'dark'

// Whenever the user explicitly chooses to respect the OS preference
// localStorage.removeItem('theme')

const checkbox = document.querySelector("#darkmode-checkbox");
const html = document.querySelector("html");

const toggleDarkMode = () => {
  if(checkbox.checked) {
    localStorage.theme = 'dark';
    html.classList.remove("light");
    html.classList.add("dark");
  } else {
    localStorage.theme = 'light';
    html.classList.remove("dark");
    html.classList.add("light");
  }
};

const checkDarkModeState = () => {
  if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    document.documentElement.classList.remove('light');
    document.documentElement.classList.add('dark');
    checkbox.checked = true;
  } else {
    document.documentElement.classList.remove('dark');
    document.documentElement.classList.add('light');
    checkbox.checked = false;
  }
};

checkDarkModeState();
checkbox.addEventListener("click", toggleDarkMode);
