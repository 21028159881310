import { Select, initTE } from "tw-elements";

const prepareMultiselects = () => {
  initTE({ Select });

  document
    .querySelectorAll("div[data-te-select-wrapper-ref]")
    .forEach((element) => {
      let duplicatedSelect = element.lastChild;
      let firstWrapper = element.firstChild;
      duplicatedSelect.classList.add("hidden");
      firstWrapper.firstChild.className = "";
      firstWrapper.firstChild.className =
        "block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mr-2 w-full text-sm font-medium";
    });
  document
    .querySelectorAll("input[data-te-select-input-ref]")
    .forEach((element) => {});
};

document.addEventListener("turbo:frame-render", (event) => {
  prepareMultiselects();
});

window.addEventListener("load", (event) => {
  prepareMultiselects();
});
