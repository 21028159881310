window.addEventListener("load", (_event) => {
  const comebackCheckBox = document.getElementsByClassName(
    "comeback--qa-anchor"
  )[0];
  const comeabackAddressHolder = document.getElementsByClassName(
    "special-trip-address-container"
  )[0];

  if (comebackCheckBox && comeabackAddressHolder) {
    comebackCheckBox.addEventListener("click", (_event) => {
      const currentState = comebackCheckBox.checked;
      if (currentState) {
        comeabackAddressHolder.classList.remove("hidden");
      } else {
        comeabackAddressHolder.classList.add("hidden");
      }
    });
  }
});
