var sideBar = document.getElementById("sidebar");
var mainContainer = document.getElementById("mainContainer");
var openSidebar = document.getElementById("openSideBar");
var closeSidebar = document.getElementById("closeSideBar");

if (sideBar && mainContainer) {
  sideBar.style.transform = "translateX(-260px)";
  mainContainer.style.setProperty('margin-left', 'auto');

  window.sidebarHandler = (flag) => {
    localStorage.sidebarToggle = flag;
    if (flag === 'open') {
      sideBar.style.transform = "translateX(0px)";
      mainContainer.style.setProperty('margin-left', '280px');
      openSidebar.classList.add("hidden");
      closeSidebar.classList.remove("hidden");
    } else {
      sideBar.style.transform = "translateX(-260px)";
      mainContainer.style.setProperty('margin-left', 'auto');
      closeSidebar.classList.add("hidden");
      openSidebar.classList.remove("hidden");
    }
  };

  const checkSidebarState = () => {
    if (localStorage.sidebarToggle === 'close' || (!('sidebarToggle' in localStorage))) {
      sidebarHandler('close');
    } else {
      sidebarHandler('open');
    }
  }
  checkSidebarState();
}